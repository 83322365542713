@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

.btn{
  color: white;
  background-color: black;
  padding: 10px 36px;
  border-radius: 10px;
}


.btn:hover{
  background-color: #222222;
  transition: 0.2s ease-in-out;
}


.lightBtn{
  color: black;
  border: 2px solid black;
  padding: 10px 36px;
  border-radius: 10px;
}


.lightBtn:hover{
  color: white;
  border: 2px solid black;
  padding: 10px 36px;
  border-radius: 10px;
  background-color: black;
  transition: all 0.1s ease-in-out;
}

.btnbig{
  color: white;
  background-color: black;
  padding: 12px 38px;
  border-radius: 10px;
}

.btnRemove {
  color: white;
  background-color: #ff6666;
  padding: 7px 30px;
  border-radius: 10px;
}

.btnRemove:hover {
  background-color: #cc0000;
  transition: 0.2s ease-in-out;
}

